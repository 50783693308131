<script>
export default defineComponent({
  setup() {
    const authStore = useAuth()
    const user = computed(() => authStore.user)
    const name = ref('')

    onMounted(() => {
      name.value = user.value.first_name
    })

    const logout = async () => {
      await authStore.logout()

      return navigateTo('/login')
    }

    return {
      logout,
      name,
    }
  },
})
</script>

<template>
  <nav class="flex items-center bg-white py-5 justify-end flex-wrap w-full">
    <div class="flex items-center justify-end w-auto pr-9 shadow-kp">
      <div class="text-sm flex items-center justify-center">
        <a
          href="#"
          class="text-lg text-blue-600 hover:text-blue-400 mr-4 bg-[#ECF6FF] w-8 h-8 flex items-center justify-center rounded-lg"
          title="Notifikasi"
        >
          <Icon name="mdi:bell-outline" class="text-2xl" />
        </a>
        <a
          href="#"
          class=" text-lg text-blue-600 hover:text-blue-400 mr-4 bg-[#ECF6FF] w-8 h-8 flex items-center justify-center rounded-lg"
          title="Sinkronisasi"
        >
          <Icon name="mdi:sync" class="text-2xl" />
        </a>
        <a
          href="#"
          class="text-[14px] font-[600] text-white bg-blue-600 w-9 h-9 rounded-full uppercase flex items-center justify-center hover:shadow-lg"
          title="logout"
          @click.prevent="logout"
        >
          {{ name?.substring(0, 2) }}
        </a>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.shadow-kp {
  box-shadow: 0px 2px 4px 0px #60617029;

  box-shadow: 0px 0px 1px 0px #28293d0a;
}
</style>
